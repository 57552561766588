import Container from "@mui/material/Container";
import styled from "@mui/material/styles/styled";
import useLayout from "../context/useLayout";
import { secondarySideBarGap, secondarySideBarWidth } from "utils/constants";
const space = secondarySideBarWidth + secondarySideBarGap;

export default function LayoutBodyWrapper({ children }) {
  const { openSecondarySideBar } = useLayout();
  return (
    <RootBox compact={openSecondarySideBar}>
      <StyledCOntainer maxWidth={"xs"}>{children}</StyledCOntainer>
    </RootBox>
  );
}

const RootBox = styled("div", {
  shouldForwardProp: (prop) => prop !== "compact",
})(({ theme, compact }) => ({
  background: "#2D2D2D",
  minHeight: "100vh",
  marginLeft: compact ? `${space}px` : "0px",
  transition: "margin-left 0.3s ease-in-out",
}));

const StyledCOntainer = styled(Container)(({ theme }) => ({
  width: "100vw",
  background: theme.palette.background.default,
  minHeight: "100vh",
  padding: "0px !important",
}));
