import {
  Stack,
  Typography,
  styled,
  useTheme,
  Link,
  Avatar,
} from "@mui/material";
import AppTable from "components/AppTable";
import Iconify from "components/Iconify";
import { FlexBetween, FlexRowAlign } from "components/flexbox";
import { t } from "i18next";
import moment from "moment";
import useAxios from "../../hooks/useAxios";

import TNXIcon from "icons/TNXIcon";
import { useEffect, useState } from "react";
import useSettings from "../../hooks/useSettings";
import useUserActivities from "../../hooks/useUserActivities";
import { TypographyGradient } from "../../components/typography";

export default function History() {
  const theme = useTheme();

  return (
    <QuestContainer>
      <Stack spacing={1} position={"relative"} zIndex={10} sx={{ flexGrow: 1 }}>
        <HistoryList />
      </Stack>
    </QuestContainer>
  );
}

const HistoryList = () => {
  const theme = useTheme();
  const { settings } = useSettings();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const { callApi } = useAxios();
  const rowHeight = 50;
  const defaultHeader = [
    {
      field: "createdAt",
      flex: 1,
      minWidth: 100,
      headerName: t("Time"),
      renderCell: ({ row }) => (
        <Stack>
          <Typography
            color={"text.secondary"}
            fontSize={"1rem!important"}
            noWrap>
            {moment(row.createdAt).format("DD MMM mm:HH")}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "action",
      flex: 1,
      minWidth: 100,
      headerName: t("Type"),
      // renderCell: ({ row }) => (
      //   <Stack>
      //     <Typography>{row.action}</Typography>
      //   </Stack>
      // ),
    },
    {
      field: "amount",
      flex: 1,
      minWidth: 100,
      headerName: t("Amount"),
      headerAlign: 'right',
      align: 'right',
      renderCell: ({ row }) => (
        <FlexRowAlign justifyContent="flex-end">
          <TypographyGradient textAlign={'right'}>
            {row.amount}
          </TypographyGradient>
        </FlexRowAlign>
      ),
    },
    // {
    //   field: "Hash",
    //   flex: 1,
    //   minWidth: 100,
    //   headerName: t("Hash"),
    //   headerAlign: "right",
    //   renderCell: ({ row }) =>
    //     row.transactionHash && (
    //       <Link
    //         to={`https://tonviewer.com/transaction/${row.transactionHash}`}
    //         sx={{ cursor: "pointer", width: "100%" }}>
    //         <FlexRowAlign width={"100%"} justifyContent='flex-end'>
    //           <Typography noWrap maxWidth={50}>
    //             {row.transactionHash}
    //           </Typography>
    //           <Iconify icon='ph:link' />
    //         </FlexRowAlign>
    //       </Link>
    //     ),
    // },
  ];

  const { userActivities, fetchUserActivities } = useUserActivities(page);

  useEffect(() => {
    if (!userActivities) return;
    setLoading(true);
    setTotal(userActivities?.total || 0);
    setData(
      userActivities?.data?.map((item) => ({
        ...item,
        id: `${item.createdAt}-${item.transactionHash}`,
      })) || [],
    );
    setLoading(false);
  }, [userActivities]);

  // const handleGetHistory = async (page = 1) => {
  //   try {
  //     setLoading(true);
  //     const res = await callApi(
  //       "get",
  //       `/user/activities?page=${page}&limit=10`
  //     );
  //     setTotal(0);
  //     setData([]);

  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   handleGetHistory();
  // }, []);

  return (
    <Stack pt={3} flexGrow={1}>
      <Typography mb={2} variant='h4' fontWeight={700}>
        {t("History")}
      </Typography>
      <AppTable
        columns={defaultHeader}
        data={data}
        rowHeight={rowHeight}
        rowSpacing={false}
        defaultPageSize={10}
        onPageChange={(page) => setPage(page + 1)}
        itemCount={total}
        loading={loading}
        disableRowHover={true}
        sx={{
          "& .MuiDataGrid-cell": {
            display: "flex",
            alignItems: "center",
            width: "100%",
            border: "none",
            borderBottom: `1px solid ${theme.palette.divider} !important`,
            padding: "0px 0.7rem !important",
          },
          "& .MuiDataGrid-topContainer": {
            "&::after": {
              display: "none !important",
            },
          },
          "& .MuiDataGrid-columnHeaders": {
            borderRadius: "0.5rem !important",
            overflow: "hidden",
          },
          "& .MuiDataGrid-columnHeader": {
            color: theme.palette.text.secondary,
            background: theme.palette.background.paper,
            height: "45px !important",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
        }}
      />
    </Stack>
  );
};

const QuestContainer = styled(Stack)(({ theme }) => ({
  margin: "auto",
  minHeight: "calc(100vh - 70px)",
  boxShadow: `0px 0px 10px rgba(0,0,0,0.2)`,
  padding: "1rem",
  position: "relative",
  "&:before": {
    content: '""',
    position: "fixed",
    width: "100%",
    height: "100vh",
    background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 4.03%, #1F2630 26.73%)`,
    zIndex: 0,
    bottom: 0,
    left: 0,
  },
}));
