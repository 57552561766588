import { lazy } from 'react';
// CUSTOM COMPONENTS
import Loadable from './Loadable';
import Quest from 'pages/mobile/quest';
import Referral from 'pages/mobile/referral';
import History from 'pages/mobile/history';
import { Dashboard } from '../pages/mobile/dashboard';

// ALL DASHBOARD PAGES
const Homepage = Loadable(lazy(() => import('pages/mobile/homepage')));

export const MobileRoutes = [
  {
    path: "",
    element: <Homepage />,
  },
  {
    path: "quest",
    element: <Quest />,
  },
  {
    path: "referral",
    element: <Referral />,
  },
  {
    path: "history",
    element: <History />,
  },
  {
    path: "dashboard",
    element: <Dashboard />,
  },
];