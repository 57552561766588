import { Stack } from "@mui/material";

export const Machine = ({ token }) => {
  return (
    <>
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ position: "relative" }}>
        <img
          src='/images/bg-mining.svg'
          alt=''
          style={{
            width: "100%",
          }}
        />
        <img
          src='/images/bg-mining.svg'
          alt=''
          style={{
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            animation: "rotate-animation 1s linear 0.1s infinite",
          }}
        />
      </Stack>
    </>
  );
};
