import { Outlet, useSearchParams } from "react-router-dom";
// CUSTOM COMPONENTS
import LayoutSideBar from "./components/DashboardSidebar";
import LayoutBodyWrapper from "./components/LayoutBodyWrapper";
// DASHBOARD LAYOUT BASED CONTEXT PROVIDER
import LayoutProvider from "./context/layoutContext";
import DashboardFooter from "./components/DashboardFooter";
import useSettings from "../../hooks/useSettings";
import { useEffect } from "react";
import { Avatar, Box, Stack, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import FlexBetween from "../../components/flexbox/FlexBetween";
import Iconify from "../../components/Iconify";
import { useTelegram } from "contexts/TelegramContext";
import { Machine } from "../../page-sections/homepage/Machine";

export default function MobileLayout({ children }) {
  const theme = useTheme();
  const { settings, saveSettings } = useSettings();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, webApp } = useTelegram();
  const isMobile = webApp?.platform === "ios" || webApp?.platform === "android";

  useEffect(() => {
    const temp = searchParams.get("token");
    if (settings?.token !== temp && !!temp && temp?.length > 0) {
      saveSettings({ ...settings, token: temp });
    }
  }, [settings]);

  return (
    <LayoutProvider>
      <LayoutBodyWrapper>
        {isMobile ? (
          <>
            {children || <Outlet />}
            <DashboardFooter />
          </>
        ) : (
          <>
            <Stack
              alignItems={"center"}
              sx={{ background: theme.palette.background.default }}
              justifyContent={"center"}
              height={"90vh"}
            >
              <Stack sx={{ transform: 'scale(0.8) translateY(-2rem)', position: 'relative' }}>
                <Machine token={'TON'} />
                <Stack alignItems={'center'} justifyContent={'center'} sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                  <Avatar
                    src="/images/ton.svg"
                    sx={{ width: 100, height: 100 }}
                  />
                </Stack>
              </Stack>
              <Typography variant="h5" fontWeight={500}>
                {t("Please login on your mobile device")}
              </Typography>
              <FlexBetween mt={"3rem"} gap={5}>
                <FlexBetween gap={1}>
                  <Avatar
                    src="/images/ton.svg"
                    sx={{ width: 28, height: 28, marginTop: '-2px' }}
                  />
                  <Typography variant="h4" fontWeight={500}>
                    TON
                  </Typography>
                </FlexBetween>
                <FlexBetween gap={1}>
                  <Avatar
                    src="/images/tai.svg"
                    sx={{ width: 25, height: 25, marginTop: '-2px' }}
                  />
                  <Typography variant="h4" fontWeight={500}>
                    TAI
                  </Typography>
                </FlexBetween>
              </FlexBetween>
            </Stack>
          </>
        )}
      </LayoutBodyWrapper>
    </LayoutProvider>
  );
}
